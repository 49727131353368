import React from 'react'
import NavbarThree from "../components/App/NavbarThree"
import Footer from "../components/App/Footer"
import Layout from "../components/App/Layout"
import Banner from '../components/SolutionCandidateExp/Sc1banner'
import FeaturedServices from '../components/SolutionCandidateExp/Sc2featuredServices'
import AboutArea from '../components/SolutionCandidateExp/Sc3aboutArea'
import FunFacts from '../components/SolutionCandidateExp/FunFacts'
import OurServices from '../components/SolutionCandidateExp/OurServices'
import CaseStudy from '../components/SolutionCandidateExp/Sc5caseStudy'
import WhyChooseUs from '../components/SolutionCandidateExp/WhyChooseUs'
import Testimonials from '../components/SolutionCandidateExp/Testimonials'
import Pricing from '../components/SolutionCandidateExp/Pricing'
import AnalysisForm from '../components/SolutionCandidateExp/AnalysisForm'
import Partner from '../components/SolutionCandidateExp/Partner'
import TeamMembers from '../components/SolutionCandidateExp/TeamMembers'

const CandidateExp = ({location}) => {
    return (
        <Layout path={location.pathname}>
            <NavbarThree />
            <Banner />
            <FeaturedServices />
            <AboutArea />
            <FunFacts />
            <OurServices />
            <CaseStudy />
            <WhyChooseUs />
            <Testimonials />
            <Pricing />
            <TeamMembers />
            <AnalysisForm />
            <Partner />
                {/* <OurBlog />*/}
            <Footer />
        </Layout>
    )
}

export default CandidateExp;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
